import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "./StateContainer";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import axios from "axios";
import NavbarModifica from "./NavbarModifica";
import { useParams } from "react-router-dom";
import ComportamentoAssistente from "./ComportamentoAssistente";
import DocumentiCarica from "./SezioniCaricaFile/DocumentiCarica";
import SitoWebUpload from "./SezioniCaricaFile/SitoWebUpload";
import { Link } from "react-router-dom";
import TestoLiberoCarica from "./SezioniCaricaFile/TestoLiberoCarica";
import NavbarGeneralEdit from "./NavbarGeneralEdit";
import QuestionsAndAnswers from "./SezioniCaricaFile/QuestionsAndAnswers";
import ZipPlugin from "../assets/plugins/wp-tailorgpt.zip";
import { MdFolder as Cartella } from "react-icons/md";
import { BsFillCheckCircleFill as Corretto } from "react-icons/bs";
import { BiErrorAlt as Errore } from "react-icons/bi";
import ModificaProgettoMobile from "./ProgettiMobile/ModificaProgettoMobile";
import AspettoChat from "./AspettoChat";

const ModificaProgetto = () => {
  const { id } = useParams();

  const {
    setLoading,
    setError,
    token,
    titoloProgetto,
    aggiornamentoDatiProgetto,
    setAggiornamentoDatiProgetto,
    setPageTitle,
    windowWidth,
    cambiaSezioneNavbar,
    setCambiaSezioneNavbar,
    setTitoloProgetto,
    width,
  } = useContext(StateContext);

  let iframeCode = `${process.env.REACT_APP_CHATBOT}?projectCode=${id}`;

  const [dettagliProgetto, setDettagliProgetto] = useState([]);

  const [navbarGeneralChange, setNavbarGeneralChange] = useState(() => {
    return localStorage.getItem("navbarGeneralChange") || "#configuraProgetto";
  });

  const [copiatoCodiceIframe, setCopiatoCodiceIframe] = useState(false);
  const [copiatoCodiceScript, setCopiatoCodiceScript] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/project/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        projectCode: id,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          setLoading(false);
          setError("");
          if (response.data.response) {
            console.log(response.data.response);
            setDettagliProgetto([response.data.response]);
            setTitoloProgetto(response.data.response.title);
          } else {
            window.location.replace("/dashboard");
          }
        }
      })
      .catch((err) => setError(err));
  }, [aggiornamentoDatiProgetto]);

  const handleCopyClick = () => {
    let iframeCode = document.getElementById("iframeCode").innerText;
    setCopiatoCodiceIframe(false);
    setCopiatoCodiceScript(false);

    navigator.clipboard
      .writeText(iframeCode)
      .then(function () {
        setCopiatoCodiceIframe(true);
      })
      .catch(function (error) {
        console.error("Errore durante la copia del codice:", error);
      });
  };

  const handleCopyClickScript = () => {
    setCopiatoCodiceIframe(false);
    setCopiatoCodiceScript(false);
    let scriptCode = document.getElementById("scriptCode").innerText;

    navigator.clipboard
      .writeText(scriptCode)
      .then(function () {
        setCopiatoCodiceScript(true);
      })
      .catch(function (error) {
        console.error("Errore durante la copia del codice:", error);
      });
  };

  const RemoveUpdateDataModal = () => {
    setAggiornamentoDatiProgetto("");
  };

  const RemoveErrorUpdateDataModal = () => {
    setAggiornamentoDatiProgetto("");
  };

  return (
    <>
      <Navbar></Navbar>
      <Sidebar></Sidebar>

      <div className='page-content'>
        <div className='main-content'>
          {windowWidth > 992 ? (
            <div className='container-fluid '>
              <div className='row'>
                <div className='col-12'>
                  <div className='page-title-box d-flex align-items-center justify-content-between p-0'>
                    <h4 className='mb-0'>Personalizza Assistente</h4>

                    <div className='p-2 d-none d-md-block col-auto'>
                      <Link
                        to={`/progetti/modifica-progetto/${id}/test-assistente`}
                        className='btn bg-orange text-white fw-bold font-size-15 '
                      >
                        Prova l'Assistente
                      </Link>
                    </div>
                  </div>
                  <div className='page-title-right pb-3'>
                    <ol className='breadcrumb m-0'>
                      <li className='breadcrumb-item'>
                        <Link
                          to='/dashboard'
                          className='text-decoration-none text-secondary'
                        >
                          Home
                        </Link>
                      </li>
                      <li className='breadcrumb-item text-secondary'>
                        Personalizza / {titoloProgetto}
                      </li>
                    </ol>
                  </div>
                </div>
                <div className='w-100 user-chat mt-4 mt-sm-0 ms-lg-0 my-3 '>
                  {aggiornamentoDatiProgetto ? (
                    aggiornamentoDatiProgetto === 200 &&
                    aggiornamentoDatiProgetto !== "" ? (
                      <div
                        className='overlay d-flex align-items-center '
                        id='overlayDataModal'
                      >
                        <div className='modal' style={{ display: "block" }}>
                          <div className='modal-dialog modal-dialog-centered'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h5 className='modal-title d-flex align-items-center gap-2'>
                                  <Corretto className='text-success fs-4' />{" "}
                                  Dati aggiornati
                                </h5>
                              </div>
                              <div className='modal-body'>
                                <p>
                                  I dati sono stati correttamente aggiornati.
                                </p>
                              </div>
                              <div className='modal-footer'>
                                <button
                                  type='button'
                                  className='btn btn-secondary'
                                  onClick={RemoveUpdateDataModal}
                                >
                                  Chiudi
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : aggiornamentoDatiProgetto !== 200 &&
                      aggiornamentoDatiProgetto !== "" ? (
                      <div
                        className='overlay d-flex align-items-center'
                        id='overlayErrorModal'
                      >
                        <div className='modal' style={{ display: "block" }}>
                          <div className='modal-dialog modal-dialog-centered'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h5 className='modal-title d-flex align-items-center gap-2'>
                                  <Errore className='text-danger fs-4' /> Errore
                                </h5>
                              </div>
                              <div className='modal-body'>
                                <p>
                                  Mi dispiace, c'è stato un problema con
                                  l'aggiornamento dei dati. Riprova più tardi.
                                </p>
                              </div>
                              <div className='modal-footer'>
                                <button
                                  type='button'
                                  className='btn btn-secondary'
                                  onClick={RemoveErrorUpdateDataModal}
                                >
                                  Chiudi
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <div className='card mb-3'>
                    <div className='card-header d-grid align-items-center bg-white p-0'>
                      <NavbarGeneralEdit
                        navbarGeneralChange={navbarGeneralChange}
                        setNavbarGeneralChange={setNavbarGeneralChange}
                      ></NavbarGeneralEdit>
                    </div>
                  </div>

                  <div className='col-12 mb-3'>
                    <div className='d-md-none'>
                      <Link
                        to={`/progetti/modifica-progetto/${id}/test-assistente`}
                        className='btn bg-orange text-white fw-bold font-size-15 w-100 py-2'
                      >
                        Prova l'Assistente
                      </Link>
                    </div>
                  </div>

                  {navbarGeneralChange === "#configuraProgetto" ? (
                    <ComportamentoAssistente progetto={dettagliProgetto} />
                  ) : navbarGeneralChange === "#aspettoChat" ? (
                    <AspettoChat progetto={dettagliProgetto} />
                  ) : navbarGeneralChange === "#includiInProgetto" ? (
                    <div className='row'>
                      <div className='col-12'>
                        {copiatoCodiceIframe ? (
                          <div
                            className='alert alert-success mt-3'
                            role='alert'
                          >
                            Codice <code>&lt;iframe/&gt;</code> copiato negli
                            appunti
                          </div>
                        ) : (
                          ""
                        )}
                        <div className='card p-2 mt-4'>
                          <div className='card-header bg-white d-flex align-items-center justify-content-between'>
                            <h6 className='m-0 fw-bold'>
                              Copia il codice dell'iframe
                            </h6>
                            <button
                              id='copyButton'
                              className='btn btn-dark'
                              onClick={handleCopyClick}
                            >
                              Copia
                            </button>
                          </div>
                          <div className='card-body'>
                            <code
                              id='iframeCode'
                              onClick={handleCopyClick}
                              style={{ cursor: "pointer" }}
                            >
                              &lt;iframe src="{iframeCode}" width="100%"
                              height="400px"&gt;&lt;/iframe&gt;
                            </code>
                          </div>
                        </div>
                      </div>
                      {/* Script */}
                      <div className='col-12'>
                        {copiatoCodiceScript ? (
                          <div
                            className='alert alert-success mt-3'
                            role='alert'
                          >
                            Codice <code>&lt;script/&gt;</code> copiato negli
                            appunti
                          </div>
                        ) : (
                          ""
                        )}
                        <div className='card p-2 mt-4'>
                          <div className='card-header bg-white d-flex align-items-center justify-content-between'>
                            <h6 className='m-0 fw-bold'>
                              Copia il codice dello script
                            </h6>
                            <button
                              id='copyButtonScript'
                              className='btn btn-dark'
                              onClick={handleCopyClickScript}
                            >
                              Copia
                            </button>
                          </div>
                          <div className='card-body'>
                            <code
                              id='scriptCode'
                              onClick={handleCopyClickScript}
                              style={{ cursor: "pointer" }}
                            >
                              &lt;script
                              src="https://app.tailorgpt.ai/js/ScriptChatbot.js"
                              async projectCode="{`${id}`}
                              "&gt;&lt;/script&gt;
                            </code>
                          </div>
                        </div>

                        <div className='card p-2 mt-4'>
                          <div className='card-header bg-white d-flex align-items-center justify-content-between'>
                            <h6 className='m-0 fw-bold'>
                              Scarica il plugin per il tuo sito Wordpress
                            </h6>
                            <a
                              id='copyButtonScript'
                              className='btn btn-dark'
                              href={ZipPlugin}
                            >
                              Download
                            </a>
                          </div>
                          <div className='card-body'>
                            <a
                              href={ZipPlugin}
                              className='text-dark text-decoration-none d-flex align-items-center gap-2'
                            >
                              <Cartella /> Plugin Wordpress Tailor GPT
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='card' style={{ width: "auto" }}>
                      <div className='card-header p-0 bg-white'>
                        <NavbarModifica
                          setCambiaSezioneNavbar={setCambiaSezioneNavbar}
                          cambiaSezioneNavbar={cambiaSezioneNavbar}
                        ></NavbarModifica>
                      </div>
                      <div
                        className='card-body py-2'
                        style={{
                          overflow: windowWidth < 600 ? "scroll" : "initial",
                          width: "auto",
                          whiteSpace: windowWidth < 600 ? "nowrap" : "normal",
                        }}
                      >
                        <div className='container-fluid position-relative p-0 py-4'>
                          <div className='row position-relative'>
                            {cambiaSezioneNavbar === "#documenti" ? (
                              <DocumentiCarica />
                            ) : cambiaSezioneNavbar === "#sitoWeb" ? (
                              <SitoWebUpload />
                            ) : cambiaSezioneNavbar ===
                              "#questionAndAnswers" ? (
                              <QuestionsAndAnswers
                                dettagliProgetto={dettagliProgetto}
                              />
                            ) : (
                              <TestoLiberoCarica
                                dettagliProgetto={dettagliProgetto}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Caricamento dei files */}
              <div className='row'>
                <div className='w-100 user-chat mt-4 mt-sm-0 ms-lg-0 my-3'></div>
              </div>
            </div>
          ) : (
            <>
              {aggiornamentoDatiProgetto ? (
                aggiornamentoDatiProgetto === 200 &&
                aggiornamentoDatiProgetto !== "" ? (
                  <div
                    className='overlay d-flex align-items-center '
                    id='overlayDataModal'
                  >
                    <div className='modal' style={{ display: "block" }}>
                      <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                          <div className='modal-header'>
                            <h5 className='modal-title d-flex align-items-center gap-2'>
                              <Corretto className='text-success fs-4' /> Dati
                              aggiornati
                            </h5>
                          </div>
                          <div className='modal-body'>
                            <p>I dati sono stati correttamente aggiornati.</p>
                          </div>
                          <div className='modal-footer'>
                            <button
                              type='button'
                              className='btn btn-secondary'
                              onClick={RemoveUpdateDataModal}
                            >
                              Chiudi
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : aggiornamentoDatiProgetto !== 200 &&
                  aggiornamentoDatiProgetto !== "" ? (
                  <div
                    className='overlay d-flex align-items-center'
                    id='overlayErrorModal'
                  >
                    <div className='modal' style={{ display: "block" }}>
                      <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                          <div className='modal-header'>
                            <h5 className='modal-title d-flex align-items-center gap-2'>
                              <Errore className='text-danger fs-4' /> Errore
                            </h5>
                          </div>
                          <div className='modal-body'>
                            <p>
                              Mi dispiace, c'è stato un problema con
                              l'aggiornamento dei dati. Riprova più tardi.
                            </p>
                          </div>
                          <div className='modal-footer'>
                            <button
                              type='button'
                              className='btn btn-secondary'
                              onClick={RemoveErrorUpdateDataModal}
                            >
                              Chiudi
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              <ModificaProgettoMobile progetto={dettagliProgetto} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ModificaProgetto;
