import React from "react";
import ImgLogo from "./img/Fulgeas_logo.png";

const PricingRegistration = () => {
  return (
    <div className='main-content m-0 bg-light-blue pagina-pricing-registration'>
      <div className='page-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='text-center d-flex justify-content-center'>
                <a className='mb-5 d-block auth-logo'>
                  <img src={ImgLogo} alt='' className='logo logo-dark' />
                </a>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-5'>
              <div className='text-center mb-5'>
                <h4>Seleziona il tuo piano</h4>
                <p className='text-muted mb-4'>
                  Scegli il piano più adatto alle tue esigenze
                </p>
              </div>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-lg-9'>
              <div className='row'>
                <div className='col-xl-4'>
                  <div className='card pricing-box text-center'>
                    <div className='card-body p-4'>
                      <div>
                        <div className='mt-3'>
                          <h5 className='mb-1'>Starter</h5>
                          <p className='text-muted'>Starter plans</p>
                        </div>

                        <div className='py-3'>
                          <i className='uil uil-edit h1 text-primary'></i>
                        </div>
                      </div>

                      <ul className='list-unstyled plan-features mt-3'>
                        <li>
                          Users:{" "}
                          <span className='text-primary fw-semibold'>1</span>
                        </li>
                        <li>
                          Storage:{" "}
                          <span className='text-primary fw-semibold'>
                            01 GB
                          </span>
                        </li>
                        <li>
                          Domain:{" "}
                          <span className='text-primary fw-semibold'>No</span>
                        </li>
                        <li>
                          Support:{" "}
                          <span className='text-primary fw-semibold'>No</span>
                        </li>
                        <li>
                          Setup:{" "}
                          <span className='text-primary fw-semibold'>No</span>
                        </li>
                      </ul>

                      <div className='py-4'>
                        <h3>
                          <sup>
                            <small>$</small>
                          </sup>{" "}
                          19/{" "}
                          <span className='font-size-13 text-muted'>
                            Per month
                          </span>
                        </h3>
                      </div>

                      <div className='text-center plan-btn my-2'>
                        <a
                          href='#'
                          className='btn btn-primary waves-effect waves-light'
                        >
                          Sign up Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-4'>
                  <div className='card pricing-box text-center'>
                    <div className='card-body p-4'>
                      <div>
                        <div className='mt-3'>
                          <h5 className='mb-1'>Professional</h5>
                          <p className='text-muted'>Professional plans</p>
                        </div>

                        <div className='py-3'>
                          <i className='uil uil-medal h1 text-primary'></i>
                        </div>
                      </div>

                      <ul className='list-unstyled plan-features mt-3'>
                        <li>
                          Users:{" "}
                          <span className='text-primary fw-semibold'>3</span>
                        </li>
                        <li>
                          Storage:{" "}
                          <span className='text-primary fw-semibold'>
                            10 GB
                          </span>
                        </li>
                        <li>
                          Domain:{" "}
                          <span className='text-primary fw-semibold'>1</span>
                        </li>
                        <li>
                          Support:{" "}
                          <span className='text-primary fw-semibold'>Yes</span>
                        </li>
                        <li>
                          Setup:{" "}
                          <span className='text-primary fw-semibold'>No</span>
                        </li>
                      </ul>

                      <div className='py-4'>
                        <h3>
                          <sup>
                            <small>$</small>
                          </sup>{" "}
                          29/{" "}
                          <span className='font-size-13 text-muted'>
                            Per month
                          </span>
                        </h3>
                      </div>

                      <div className='text-center plan-btn my-2'>
                        <a
                          href='#'
                          className='btn btn-primary waves-effect waves-light'
                        >
                          Sign up Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-xl-4'>
                  <div className='card pricing-box text-center'>
                    <div className='card-body p-4'>
                      <div>
                        <div className='mt-3'>
                          <h5 className='mb-1'>Unlimited</h5>
                          <p className='text-muted'>Unlimited plans</p>
                        </div>

                        <div className='py-3'>
                          <i className='uil uil-layer-group h1 text-primary'></i>
                        </div>
                      </div>

                      <ul className='list-unstyled plan-features mt-3'>
                        <li>
                          Users:{" "}
                          <span className='text-primary fw-semibold'>5</span>
                        </li>
                        <li>
                          Storage:{" "}
                          <span className='text-primary fw-semibold'>
                            40 GB
                          </span>
                        </li>
                        <li>
                          Domain:{" "}
                          <span className='text-primary fw-semibold'>4</span>
                        </li>
                        <li>
                          Support:{" "}
                          <span className='text-primary fw-semibold'>Yes</span>
                        </li>
                        <li>
                          Setup:{" "}
                          <span className='text-primary fw-semibold'>Yes</span>
                        </li>
                      </ul>

                      <div className='py-4'>
                        <h3>
                          <sup>
                            <small>$</small>
                          </sup>{" "}
                          49/{" "}
                          <span className='font-size-13 text-muted'>
                            Per month
                          </span>
                        </h3>
                      </div>

                      <div className='text-center plan-btn my-2'>
                        <a
                          href='#'
                          className='btn btn-primary waves-effect waves-light'
                        >
                          Sign up Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingRegistration;
